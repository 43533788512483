<template>
  <v-container fluid>
    <v-row class="d-flex justify-space-between">
      <h2>Artist Sales</h2>
      <v-btn class="ma-2" color="primary" @click="downloadExcel()">
        Export All <v-icon>mdi-table</v-icon>
      </v-btn>
    </v-row>
      <date-range @getDates="getSales"></date-range>
    <v-alert v-if="!Object.keys(artists).length && loaded" type="info">
      <p>No data to display.</p>
    </v-alert>
    <v-expansion-panels
      v-if="Object.keys(artists).length"
      accordion
      v-model="panel"
    >
      <v-expansion-panel v-for="(artist, index) of artists" :key="index">
        <v-expansion-panel-header>
          <div class="d-flex justify-space-between align-center">
              <h4>{{ index }} ({{ artist.total }})</h4>
              <v-btn class="ma-2" color="primary" @click.stop="downloadExcel(index)">
              <v-icon>mdi-table</v-icon>
            </v-btn>
          </div>
          <!-- <div>
              <v-data-table
                :headers="summaryHeaders"
                :items="getArtistSummary(index)"
                class="elevation-1 my-2 mr-4"
                hide-default-footer
                dense
              ></v-data-table>
            </div> -->
          </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-data-table
            :headers="headers"
            :items="artist.products"
            :items-per-page="5"
            class="elevation-1"
          >
            <template v-slot:[`item.image`]="{ item }">
              <v-img
                class="my-2"
                max-height="50px"
                max-width="50px"
                :src="`${imageUrl}/100px/${getImageToDisplay(item.image)}`"
              ></v-img>
            </template>
          </v-data-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
import DateRange from "@/components/DateRange";
import ReportApi from "@/api/admin/report";
import _ from "lodash";
import xlsx from "xlsx";

export default {
  components: {
    DateRange,
  },
  data() {
    return {
      panel: 0,
      loaded: false,
      imageUrl: process.env.VUE_APP_IMG_URL,
      artists: [],
      sales: [],
      summaryHeaders: [
        {
          text: "Magnets",
          align: "end",
          value: "Magnet",
        },
        {
          text: "Mugs",
          align: "end",
          value: "Mug",
        },
        {
          text: "Greeting Cards",
          align: "end",
          value: "Greeting Card",
        },
        {
          text: "Small Mounted Prints",
          align: "end",
          value: "Small Mounted Print",
        },
        {
          text: "Open Edition Giclees",
          align: "end",
          value: "Open Edition Giclee",
        },
        {
          text: "Limited Edition Giclees",
          align: "end",
          value: "Limited Edition Giclee",
        },
      ],
      headers: [
        {
          text: "",
          width: "5%",
          value: "index",
        },
        {
          text: "Image",
          sortable: false,
          value: "image",
        },
        {
          text: "Total",
          align: "end",
          width: "8%",
          value: "numItems",
        },
        {
          text: "Title",
          width: "60%",
          value: "title",
        },
        {
          text: "Product",
          value: "category",
        },
        {
          text: "SKU",
          value: "sku",
        },
      ],
    };
  },
  methods: {
    getSales(dates) {
      ReportApi.loadArtistSales({ dateRange: dates }).then((results) => {
        this.sales = results.data;
        this.artists = _.groupBy(this.sales, "artist");
        for (const artist in this.artists) {
          let totalSalesItems = 0;
          const ranked = this.artists[artist].map((item, index) => {
            totalSalesItems += item.numItems;
            return { ...item, index: index + 1 };
          });
          this.artists[artist] = { total: totalSalesItems, products: ranked };
        }
        this.loaded = true;
      });
    },
    getArtistSummary(artist) {
      const data = this.sales.filter((item) => item.artist === artist);
      const summary = {
        Magnet: 0,
        "Greeting Card": 0,
        Mug: 0,
        "Open Edition Giclee": 0,
        "Limited Edition Giclee": 0,
        "Small Mounted Print": 0,
      };
      data.map((item) => {
        summary[item.category] += Number(item.numItems);
      });
      return [summary];
    },
    downloadExcel(artist) {
      const headers = [
        "artist",
        "artistCode",
        "category",
        "image",
        "numItems",
        "sku",
        "title",
        "unitPrice",
        "skuSequence",
      ];
      console.log(artist)
      const sales = artist ? this.sales.filter(x => x.artist === artist) : this.sales
      const workbook = xlsx.utils.book_new();
      const worksheet = xlsx.utils.json_to_sheet(sales, {
        header: headers,
      });
      xlsx.utils.book_append_sheet(workbook, worksheet, "Sales");
      xlsx.writeFile(workbook, "ARTIST-SALES.xlsx");
    },
    async created() {},
  },
};
</script>
